import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Download from "pages/download";
import DownloadEetmarkets from "pages/download/eetmarkets.js";
import DownloadEetall from "pages/download/eetall";
import notFound from "pages/notFound";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Download} />
      <Route path="/download/eetmarkets" component={DownloadEetmarkets} />
      <Route path="/down/eetall" component={DownloadEetall} />
      <Route path="*" component={notFound} />
    </Switch>
  </Router>
);
