let linkConfig = {
  download: {
    ios: {
      eetrade: "https://apps.apple.com/cn/app/id6465077365",
    },
    androidUrl: {
      eetall: "https://downloads.btgwxz8.com/source/material/EETGW_ALL.apk",
      eetrade: "https://downloads.btgwxz8.com/source/material/EET_GW.apk",
      eetmarkets: [
        "https://mp-074d31c0-761b-4193-8c5c-a4b247ef9811.cdn.bspapp.com/EET Markets/EETMARKETS_GW.apk",
        "https://downloads.gwappxz1.com/source/material/EETMARKETS_GW.apk",
        "https://downloads.gwappxz1.com/source/material/EETMARKETS_TUIJIAN.apk",
      ],
    },
  },
};

export { linkConfig };
